<template>
  <el-card  class="card_content" shadow="never">
      <div slot="header">
        <span class="iconfont icon-SystemConfig">&nbsp;腾讯云对象存储</span>
      </div>
       <el-form :model="form" ref="form" label-width="200px" size="small" style="width:600px">
    <el-form-item label="Bucket">
      <el-input v-model="form.bucket"></el-input>
    </el-form-item>
    <el-form-item label="AccessId">
      <el-input v-model="form.secret_id"></el-input>
    </el-form-item>
    <el-form-item label="AccessKey">
      <el-input v-model="form.secret_key"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" style="width:100px" @click="onSubmitEdit">保存</el-button>
    </el-form-item>
  </el-form>
    </el-card>

</template>
<script>
import { getSystemConfigApi, editSystemConfigApi } from "../../../api/data";
export default {
  data() {
    return {
      form: {
        bucket: "",
        secret_id: "",
        secret_key: ""
      }
    };
  },
    created() {
    this.initSms();
  },
  methods: {
    initSms() {
      getSystemConfigApi({ type: "tx_colud" }).then(res => {
        if (res) {
          this.form = res.data;
        }
      });
    },
    onSubmitEdit() {
      editSystemConfigApi(this.form).then(res => {
        if (res) this.$message.success(res.msg);
      });
    }
  }
};
</script>
<style lang='scss'>
</style>